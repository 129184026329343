// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "aegis-theme" as aegisTheme;
@use "aegis-styles"; // Put global styles in this file

// Include the common styles for Angular Material. These are used by our custom theme as well as for general styling.
$fec-ui-typography: mat.define-typography-config(
  $headline-1: mat.define-typography-level(1.4375rem, 2rem, 400) /*23px, 32px, maps to .mat-headline-1 */,
  $headline-2: mat.define-typography-level(1.4375rem, 2rem, 400) /*23px, 32px, maps to .mat-headline-2 */,
  $headline-3: mat.define-typography-level(1.4375rem, 2rem, 400) /*23px, 32px, maps to .mat-headline-3 */,
  $headline-4: mat.define-typography-level(1.4375rem, 2rem, 400) /*23px, 32px, maps to .mat-headline-4 */,
  $headline-5: mat.define-typography-level(1.4375rem, 2rem, 400) /*23px,32px, maps to h1, .mat-h1 & .mat-headline-5*/,
  $headline-6: mat.define-typography-level(1.25rem, 2rem, 400) /*20px, 32px, maps to h2, .mat-h2 & .mat-headline-6 */,
  $subtitle-1: mat.define-typography-level(0.9375rem, 1.75rem, 400) /*15px,28px, maps to h3, .mat-h3 & .mat-subtitle-1*/,
  $subtitle-2: mat.define-typography-level(0.75rem, 1.75rem, 400) /*12px,28px, .mat-body-strong & .mat-subtitle-2 */,
  $body-1: mat.define-typography-level(1rem, 1.5rem, 400) /* 16px, 24px, maps to h4, .mat-h4 & .mat-body-1 */,
  $body-2: mat.define-typography-level(1rem, 1.5rem, 400) /* 16px, 24px, maps to p */,
  $caption: mat.define-typography-level(0.875rem, 2.25rem, 400) /* 14px, 36px, maps to .mat-small or .mat-caption */,
  $button: mat.define-typography-level(0.875rem, 2.25rem, 500, 1.5rem, 0.078125rem) /* 14px, 36px */,
);

@include mat.typography-hierarchy($fec-ui-typography);
@include mat.core();
@include mat.strong-focus-indicators(); // Improved WCAG accessibility

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fec-ui-primary: mat.define-palette(aegisTheme.$aegis-primary-palette);
$fec-ui-accent: mat.define-palette(aegisTheme.$aegis-accent-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fec-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fec-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $fec-ui-primary,
      accent: $fec-ui-accent,
      warn: $fec-ui-warn,
    ),
    typography: $fec-ui-typography,
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fec-ui-theme);
@include mat.strong-focus-indicators-theme($fec-ui-theme); // Improved WCAG accessibility

// Add tailwindcss for CSS utilities (https://tailwindcss.com)
@tailwind base;
@tailwind components;
@tailwind utilities;
