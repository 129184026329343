:root {
  --primary-50: #e6f1f6;
  --primary-100: #cce3ee;
  --primary-200: #b3d6e5;
  --primary-300: #99c8dd;
  --primary-400: #80bad4;
  --primary-500: #66accb;
  --primary-600: #4d9ec3;
  --primary-700: #3391ba;
  --primary-800: #1a83b2;
  --primary-900: #0075a9;
  --primary-a100: #f7fcff;
  --primary-a200: #c4e9ff;
  --primary-a300: #91d6ff;
  --primary-a700: #78cdff;

  --accent-50: #fef2e5;
  --accent-100: #fee0bd;
  --accent-200: #fdcb91;
  --accent-300: #fcb665;
  --accent-400: #fba644;
  --accent-500: #fa9623;
  --accent-600: #f98e1f;
  --accent-700: #f9831a;
  --accent-800: #f87915;
  --accent-900: #f6680c;
  --accent-a100: #ffffff;
  --accent-a200: #fff3ed;
  --accent-a400: #ffd2ba;
  --accent-a700: #ffc2a1;

  --dark-primary-text: #00000057;
  --light-primary-text: #fff;
  --green: #00826a;
  --red: #ff0000;
  --white: #ffffff;
  --grey: #999999;
  --light-grey: #cccccc;

  --md-dark: rgba(0, 0, 0, 0.54);
  --primary-background: #f9fdff;

  --file-status-Draft: #bbdefb;
  --file-status-Validating: #d7bbfb;
  --file-status-ValidatedHasErrors: #ffb0b0;
  --file-status-ValidatedHasWarnings: #ffd964;
  --file-status-ValidationFailed: #ff8888;
  --file-status-Validated: #abfeaf;
  --file-status-FilingInProgress: #d7bbfb;
  --file-status-FecValidating: #e8ffe9;
  --file-status-FilingFailed: #ff8888;
  --file-status-Filed: #dcedc8;
}
