html,
body {
  height: 100%;
}

a {
  text-decoration: none;
}

.material-icons.md-dark {
  color: var(--md-dark);
}

.mat-mdc-tab {
  background-color: #d1e9f3 !important;
  opacity: 1 !important;
}

.mat-mdc-raised-button {
  margin-bottom: 0.15rem !important;
}

.apt-mat-tab-group-2-line-header > .mat-mdc-tab-header .mat-mdc-tab {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

/* Workaround for making the bottom border of the selected tab invisible. The "right" way to do this is to use a component theme,
  but that's a lot of work and may not be forward compatible with the expected theming updates in Angular Material 17+ */
.mdc-tab--active {
  background-color: white !important;
  color: var(--primary-900) !important;
  box-shadow: inset 0 0.12rem 0 0 var(--primary-900); /* Adds the top border to the selected tab */

  .mdc-tab-indicator__content--underline {
    border-color: #fff !important;
  }
}

.mat-ink-bar {
  top: 0;
  visibility: hidden;
  background-color: var(--primary-900) !important;
}

/* focus style */
.mat-mdc-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-primary .mat-mdc-tab-link:not(.mat-tab-disabled):focus,
.mat-mdc-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
  visibility: visible;
}

.apt-success {
  color: var(--green);
}
.apt-danger {
  color: var(--red);
}

// Start Accordion Styles
.apt-accordian-header-selected {
  background-color: var(--primary-background);
}
.apt-accordion-item-header:hover {
  background-color: var(--primary-background);
}
.apt-accordion-item:not(:first-child) .apt-accordion-item-header {
  border-top: solid 0.1rem var(--light-grey);
}
// End Accordion Styles

.text-primary-900 {
  color: var(--primary-900);
}

.mat-mdc-unelevated-button.text-primary-900 {
  color: var(--primary-900) !important;
}

.mat-mdc-button.text-primary-900 {
  color: var(--primary-900);
}

.apt-results-container {
  border: 0.05rem solid var(--dark-primary-text);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
}

.apt-dark-border-color {
  border-color: var(--dark-primary-text);
}

.apt-background-color {
  background-color: var(--primary-background);
}

.mat-mdc-form-field.apt-p-0 > div {
  padding: 0;
}

.mat-mdc-form-field.apt-m-0 > div {
  margin: 0;
}

/* Workaround for tailwindCss adding a right border to the placeholder in Angular Material controls such as textboxes and select.
   More info: https://stackoverflow.com/questions/74621735/ */
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

/* Dialog styles */

// The default style is to use black at 60% opacity for text in a dialog. This overrides that.
.mat-mdc-dialog-container .mdc-dialog__content {
  color: #000 !important;
}

/* End Dialog styles */
